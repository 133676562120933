/* Colors site 

  RED #FE4523
  BLUE #0f2667
  
*/

/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none !important;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: "Centra", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: #ff001f;
}
/************ Navbar Css ************/
.img-logo {
  width: 75%;
}
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 5px 0;
  background-color: #0f2667;
}
nav.navbar a.navbar-brand {
  width: 7%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
}
.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url("./assets/img/banner-bg.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: #fe4523;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.align-items-img {
  text-align: center;
}
.banner img {
  animation: updown 3s linear infinite;
  width: 70%;
}
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Players Css ************/
.block-teams-control {
  position: relative;
  width: 100%;
  min-width: 128px;
  max-width: 128px;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s ease;
  margin-bottom: 20px;
}
.team-block-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 4;
  width: 40px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.block-teams-img {
  position: relative;
  border: 1px solid #ffff;
}
.block-teams-img:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 25, 29, 0);
  transition: 0.5s ease;
  content: "";
}

.block-teams-picture {
  transition: 0.5s ease;
  position: relative;
}

.block-teams-picture::before {
  display: block;
  width: 100%;
  padding-top: 48.4375%;
  content: "";
}

.block-teams-picture-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.picture-img-picture {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
}

.picture-img-picture img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover;object-position: center;";
}

.skill {
  padding: 0 0 50px 0;
  position: relative;
  background-color: #01131e;
}
.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
  font-size: 45px;
  font-weight: 700;
}
.skill p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.player-info {
  height: 100%;
}

.item {
  position: relative;
  /* padding: 24px 10px 20px; */
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
  background-color: #2a292e;
  border-radius: 5px;
}

.player-nickname {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 7px;
  color: #fe4523;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.44;
}
.player-country {
  width: 16px;
  height: 12px;
  margin-right: 10px;
  margin: 0 8px 0 0 !important;
}

.player-name {
  margin-bottom: 8px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.17;
}

.item img {
  width: 100%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

.teammate-links {
  width: 100%;
  -ms-flex-item-align: end;
  align-self: flex-end;
  position: relative;
  z-index: 2;
}
.teammate-links-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fe4523;
  width: 100%;
  z-index: 10;
}

.follow-link {
  padding: 10px 10px;
}

.follow-link svg {
  font-size: 22px;
  color: #fff;
  transition: color 0.25s ease-in-out;
}

.icon-fallback-text {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
}

/************ Partners Css ************/
.bg-blue {
  background-color: #0f2667;
}
.partners {
  text-align: center;
}
.partners h2 {
  font-size: 45px;
  font-weight: 700;
  padding: 40px 0;
}
.padding-partners {
  padding: 40px 0;
}
.partners-list {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.partner {
  margin: 0 10px;
}

.partner img {
  width: 135px;
  max-width: 135px;
  height: 35px;
  object-fit: contain;
}

/************ About Css ************/

.nav-about-style .nav-item {
  width: 50% !important;
}

.history-box p {
  text-align: left !important;
  width: 80% !important;
}

/************ Tournaments Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}
.project p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background-color: #fe4523;

  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  background-color: #fe4523;

  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}
/* Matches CSS */

.match-box {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  background-color: #eeedef;
  transition: 0.3s;
  min-height: 60px;
  background-color: #fff;
  margin-bottom: 10px;
}

.match-box-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  transition: 0.6s;
}

.match-box:not(:last-child) {
  margin-bottom: 10px;
}

.match-box-type-game {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 50px;
  min-height: 100%;
  background-color: #dddde0;
  display: flex;
}

.match-box-type-game img {
  max-width: 25px;
  max-height: 25px;
}

.match-box-body {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
  display: flex;
  width: 100%;
  padding: 15px 0 11px 12px;
  overflow: hidden;
}
.match-box-date {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 14px;
  overflow: hidden;
  color: #707070;
  font-size: 12px;
  line-height: 1.17;
  white-space: nowrap;
  align-items: center;
  min-width: 130px;
  margin-bottom: 0;
}

.match-box-date span {
  margin-right: 3px;
  font-weight: 700;
}

.match-box-opponents {
  position: relative;
  display: -ms-grid;
  display: grid;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr fit-content(10px) 1fr;
  grid-template-columns: 1fr fit-content(10px) 1fr;
}
.match-box-team {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.match-box-team-icon {
  margin-right: 8px;
}

.match-box-team-icon img {
  width: 23px;
}
.match-box-team-text {
  overflow: hidden;
  color: #27262b;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.21;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: 0.5s ease;
}

.match-box-separator {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 100px;
}

.match-box-result {
  margin-right: 5px;
  margin-left: 5px;
}

/************ Tournament Css ************/

.card-item-tournament {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
  margin-bottom: 20px;
}

.card-item-tournament a {
  text-decoration: none;
}

.card-item-tournament:hover {
  box-shadow: 0 10px 15px 0 hsla(0, 0%, 56.9%, 0.16);
  -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
}

.item-tournament-img {
  position: relative;
}

.item-tournament-img picture {
  position: relative;
}

.item-tournament-img picture::before {
  display: block;
  width: 100%;
  padding-top: 66.41221%;
  content: "";
}

.item-tournament-img picture .picture-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.picture-img {
  outline: none;
}

.picture-picture {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
}
.picture-picture img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover;object-position: center;";
}

.card-item-svg {
  position: absolute;
  bottom: -26px;
  left: 50%;
  z-index: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background-color: #fff;
  border: 4px solid #eeedef;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.card-item-svg img {
  width: 70%;
}

.card-tournament-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  padding: 50px 20px 20px;
}

.card-item-date {
  margin-bottom: 15px;
  overflow: hidden;
  color: #707070;
  font-size: 14px;
  line-height: 1.21;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card-item-title {
  margin-bottom: auto;
  color: #27262b;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.44;
}

.card-item-info {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 26px;
  padding-top: 18px;
  border-top: 1px solid #eeedef;
}

.card-item-info-price {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.card-item-info-price > svg {
  right: -0.5px;
  bottom: -0.5px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.card-item-info-price-text {
  display: inline-block;
  margin-left: 9px;
  overflow: hidden;
  color: #27262b;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  text-overflow: ellipsis;
}

.card-item-info-type {
  color: #707070;
  font-size: 14px;
  line-height: 1.5;
}

/************ Players Css ************/
.contact {
  background-color: #fe4523;
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}
.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  border-top: 1px solid #c1c1c1c1;
  box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.5);
}
.img-logo-footer {
  margin-top: 40px;
}
.newsletter-bx {
  background: #ffffff;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: #fe4523;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: #fe4523;
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

/* ================================================================= MEDIAS QUERIES ===========================================================*/

@media only screen and (max-width: 767px) {
  .banner {
    padding: 100px 0 100px 0;
  }
  .banner .tagline {
    width: 100%;
    text-align: center;
  }
  .img-logo {
    width: 40px;
  }
  .ms-auto {
    margin-top: 10px;
  }
  .banner img {
    animation: updown 3s linear infinite;
    width: 70%;
    margin-top: 20px;
  }
  .block-teams-control {
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .skill h2 {
    margin-bottom: 20px;
  }

  .skill-bx {
    padding: 30px 50px;
  }

  .project .nav.nav-pills {
    width: 100%;
  }
  .project .nav.nav-pills .nav-link {
    font-size: 0.7em;
  }
  .footer {
    text-align: center;
  }

  .img-logo-footer {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .banner h1 {
    font-size: 3em;
  }
}
